<template>
  <div class="container">
    <div class="columns is-vcentered is-mobile">
      <div class="column is-1-desktop is-2-touch">
        <figure class="image is-64x64">
          <img v-bind:src="icon" class="is-rounded">
        </figure>
      </div>
      <div class="column is-5-mobile is-3-desktop">
        <h1 class="title poppins">{{ name }}</h1>
      </div>
      <div class="column  is-2">
        <b-button type="is-primary" v-if="isBot">Zarządzaj</b-button>
        <b-button type="is-danger" v-else v-on:click="open(`https://discord.com/oauth2/authorize?client_id=${botId}&permissions=347208&scope=bot&guild_id=${id}`)">Dodaj bota</b-button>
<!--        <b-button type="is-danger" v-else v-on:click="open(`http://localhost:8080/guild-callback`)">Dodaj bota</b-button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Server",
  props: {
    id: String,
    name: String,
    icon: String,
    isBot: Boolean
  }, created() {
    this.botId = process.env.VUE_APP_BOT_ID;
    this.api = process.env.VUE_APP_API_URL;
  }, data() {
    return {
      botId: undefined,
      api: undefined
    }
  }, methods: {
    open(url) {
      window.open(url, '', 'width=800, height=600');
    }
  }
}
</script>

<style scoped>

</style>
