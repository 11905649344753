<template>
  <div class="home">
    <Header></Header>
    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title poppins" style="padding-bottom: 30px;">Dlaczego Delover?</h1>
      </div>
      <div class="container">
        <div class="columns">
          <div class="column is-6 is-vertical-center">
            <h1 class="title poppins has-text-centered">Moderacja</h1>
            <h1 class="subtitle poppins has-text-centered">Posiadamy wiele komend moderacyjnych</h1>
          </div>
          <div class="column is-6">
            <b-image :rounded="true"
                     src="/moderation.png"
                     ratio="3by2" :responsive="true"
            ></b-image>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns reverse-columns">
          <div class="column is-6">
            <b-image :rounded="true"
                     src="/fun.png"
                     ratio="3by2" :responsive="true"
            ></b-image>
          </div>
          <div class="column is-6 is-vertical-center">
            <h1 class="title poppins has-text-centered">Zabawa</h1>
            <h1 class="subtitle poppins has-text-centered">Bot posiada duzo zabawnych funkcji</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-6 is-vertical-center">
            <h1 class="title poppins has-text-centered">Innowacja</h1>
            <h1 class="subtitle poppins has-text-centered">Nasz bot posiada dużo innowacyjnych zastosowań</h1>
          </div>
          <div class="column is-6">
            <b-image :rounded="true"
                     src="/innovation.png"
                     ratio="3by2" :responsive="true"
            ></b-image>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container has-text-centered" style="margin-bottom: 40px">
        <h1 class="title poppins">Zaufało nam ponad x serwerów</h1>
        <h2 class="subtitle poppins">Specjalnie dla Ciebie utworzyliśmy tą zakładkę, abyś widział nasze piękne statystyki!</h2>
      </div>
      <div class="container is-max-desktop">
        <div class="box">
          <div class="columns is-centered">
            <div class="column is-3">
              <h1 class="title poppins has-text-centered" style="margin-bottom: 6px;">Serwery</h1>
              <h2 class="subtitle poppins has-text-centered" v-if="servers !== -1"  style="margin-top: 5px">{{ servers }}</h2>
              <b-skeleton position="is-centered" width="20%" :animated="true" v-else></b-skeleton>
            </div>
            <div class="column is-3">
              <h1 class="title poppins has-text-centered" style="margin-bottom: 6px;">Użytkownicy</h1>
              <h2 class="subtitle poppins has-text-centered" v-if="users !== -1"  style="margin-top: 5px">{{ users }}</h2>
              <b-skeleton position="is-centered" width="20%" :animated="true" v-else></b-skeleton>
            </div>
            <div class="column is-3">
              <h1 class="title poppins has-text-centered" style="margin-bottom: 6px;">Kanały</h1>
              <h2 class="subtitle poppins has-text-centered" v-if="channels !== -1"  style="margin-top: 5px">{{ channels }}</h2>
              <b-skeleton position="is-centered" width="20%" :animated="true" v-else></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title poppins">Gotowy na zmianę Twojego serwera Discord?</h1>
        <h1 class="subtitle poppins" style="margin-bottom: 10px">Dodaj Delovera już teraz!</h1>
        <a class="button is-primary is-large">
          Dodaj bota
        </a>
      </div>
    </section>
    <footer class="footer">
      <div class="container has-text-centered">
        <p>Strona stworzona przez <b><a href="https://rkuba.pl">rkubapl</a></b>.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import axios from 'axios';

export default {
  name: 'Home',
  components: {Header},
  data() {
    return {
      channels: -1,
      servers: -1,
      users: -1
    }
  }, created() {
    console.log(process.env)
    axios.get(process.env.VUE_APP_API_URL + "/stats").then(response => {
      console.log(response)
      if(response.data.status === 200) {
        this.users = response.data.data.users.all;
        this.channels = response.data.data.channels;
        this.servers = response.data.data.servers;
      }
    })
  }
}
</script>
