<template>
  <div class="preview">
    <div class="wrapper">
      <div class="side-colored" v-bind:style="`background-color: ${color};`"></div>
      <div class="embed">
        <div class="card-block">
          <div class="embed-inner"><div class="embed-author" v-if="authorName || authorIcon"><img class="embed-author-icon" v-if="authorIcon" v-bind:src="authorIcon"><a class="embed-author-name" v-bind:href="authorLink">{{ authorName }}</a></div><div class="embed-title"><a v-bind:href="link">{{ title }}</a></div><div class="embed-description">{{ description}}</div>
            <div class="fields">
            <div class="field false" v-for="field in fields" v-bind:key="field">
              <div class="field-name">{{ field.name }}</div>
              <div class="field-value">{{ field.description }}</div>
            </div>
          </div></div>
          <img class="embed-thumb" v-if="image" v-bind:src="image" style="height: 507px;"></div>
        <div class="embed-footer" v-if="footer"><span> {{ footer }}</span></div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscordEmbed",
  props: {
    title: String,
    link: String,
    description: String,
    image: String,
    fields: Array,
    authorName: String,
    authorIcon: String,
    authorLink: String,
    footer: String,
    color: String
  }
}
</script>

<style scoped lang="sass">
$width: 120px
$top: 20px
$left: 20px

a.patreon-link
  display: flex
  box-sizing: border-box
  width: 100%
  height: auto
  z-index: 10
  text-decoration: none

.badge-container
  width: 100%
  display: flex
  flex-direction: column
  padding: 5px 10px
  background: rgba(0,0,0,0.5)
  border: 1px solid rgba(255,255,255,0.5)
  text-align: center

  .badge-icon
    background: url('https://s3.amazonaws.com/patreon_public_assets/toolbox/patreon_white.png') center center no-repeat
    background-size: contain
    width: 100%
    height: 20px

  .badge-text
    font-family: 'Lato', sans-serif
    font-size: 12px
    padding: 2px
    color: #fff

    &.badge-text-bottom
      position: relative
      top: -2px



.preview
  background: #36393e
  display: flex
  box-orient: vertical
  flex-direction: column

.wrapper
  display: flex
  max-width: 520px

.container
  margin-top: 50px

.side-colored
  width: 4px
  border-radius: 3px 0 0 3px
  background: #4f545c

.embed
  border-radius: 0 3px 3px 0
  background: rgba(46,48,54,.3)
  border-color: rgba(46,48,54,.6)
  //display: flex
  padding: 8px 10px
  color: rgba(255,255,255,0.6)
  font-size: 14px

  .card-block
    padding: 0
    display: flex
    margin-bottom: 10px

  a
    color: #0096cf

  img.embed-thumb
    max-height: 80px
    max-width: 80px
    border-radius: 3px
    flex-shrink: 0
    width: auto
    object-fit: contain
    margin-left: 20px

  .embed-footer
    font-size: 12px
    span
      color: rgba(255,255,255,0.6)

  .embed-inner

    .embed-title
      color: #fff

    .embed-author
      display: flex
      align-items: center
      margin-bottom: 5px

      img.embed-author-icon
        margin-right: 9px
        width: 20px
        height: 20px
        object-fit: contain
        border-radius: 50%

      .embed-author-name
        display: inline-block
        font-weight: 600
        font-size: 14px
        color: #fff !important

    .fields
      display: flex
      flex-wrap: wrap
      flex-direction: row
      box-lines: miltiple
      margin-top: -10px

      .field
        flex: 0
        box-flex: 1
        padding-top: 10px
        max-width: 506px
        min-width: 100%

        &.inline
          box-flex: 1
          flex: 1
          min-width: 150px
          flex-basis: auto

        .field-name
          color: #fff
          font-size: 14px
          margin-bottom: 4px
          font-weight: 600

        .field-value
          color: rgba(255,255,255,0.7)
          font-size: 14px
          font-weight: 500
          line-height: 1.1em
          white-space: pre-wrap
          margin-top: 6px
          word-wrap: break-word

</style>
