<template>
  <div>
  <b-navbar>
    <!--          <template #brand>-->
    <!--            <b-navbar-item tag="router-link" :to="{ path: '/' }">-->
    <!--              <img-->
    <!--                src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"-->
    <!--                alt="Lightweight UI components for Vue.js based on Bulma"-->
    <!--              >-->
    <!--            </b-navbar-item>-->
    <!--          </template>-->
    <template #start>
      <b-navbar-item tag="div" class="is-clickable" v-on:click="$router.push('/')">
        <h1 class="title poppins is-4">Delover</h1>
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item v-bind:href="serverInvite">
        Serwer Discord
      </b-navbar-item>
      <b-navbar-item v-bind:href="`https://discord.com/api/oauth2/authorize?client_id=${botId}&permissions=8&scope=bot`">
        Dodaj bota
      </b-navbar-item>
      <b-navbar-item to="/regulamin">
        Regulamin
      </b-navbar-item>
      <b-navbar-item tag="div" v-if="userData !== undefined">
        <b-dropdown :triggers="['hover']" aria-role="list" tag="div" style="transform: translateY(10%);">
          <template #trigger>
            <img v-bind:src="userData.avatar" class="is-rounded" style="display:inline-block;">
            <h2 class="subtitle is-5 poppins" style="display:inline-block; transform: translateY(-30%)" >&nbsp;{{ userData.username }}</h2>
          </template>

          <b-dropdown-item aria-role="listitem">Zarządzaj serwerami</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" v-on:click="$emit('changeMode')">Dark mode/light mode</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Wyloguj się</b-dropdown-item>
        </b-dropdown>
      </b-navbar-item>
      <b-navbar-item tag="div" v-if="userData === undefined">
        <div class="buttons">
          <button class="button is-primary" v-if="!logged" @click="login">
            <strong>Zaloguj się</strong>
          </button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
  <LoginModal v-bind:show-login-modal="showLoginModal"></LoginModal>
  <InvalidSession v-bind:show-modal="showInvalidSessionModal"></InvalidSession>
  </div>
</template>

<script>
import LoginModal from "@/components/LoginModal";
import InvalidSession from "@/components/InvalidSession";
import axios from 'axios';

export default {
  name: "Navbar",
  components: {
    LoginModal,
    InvalidSession
  },
  data() {
    return {
      logged: false,
      userData: undefined,
      showLoginModal: false,
      showInvalidSessionModal: false,
      botId: -1,
      serverInvite: undefined
    }
  }, methods: {
    login() {
      this.showLoginModal = true;

      window.open("http://localhost:8080/callback", '', 'width=800, height=600');
      const bc = new BroadcastChannel('auth');

      console.log(this)


      const router = this.$router;
      const vue = this;

      bc.onmessage = function (ev) {
        console.log(ev)
        if(ev.data === "authorized") router.push({ path: '/dashboard'} );
        if(ev.data === "not authorized") vue.showLoginModal = false;
      }

    }
  }, created() {
    this.botId = process.env.VUE_APP_BOT_ID;
    this.serverInvite = process.env.VUE_APP_SUPPORT_SERVER;

    axios.get(process.env.VUE_APP_API_URL + "/@me").then(response => {
      if(response.data.status) {
        this.userData = response.data.data;
      } else {
        this.showInvalidSessionModal = true;
      }
    }).catch(() => this.showInvalidSessionModal = true);
  }
}
</script>

<style scoped>
.is-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
