<template>
  <section class="hero is-fullheight background-color-theme">
    <div class="hero-head background-color-theme">
      <template>
        <Navbar></Navbar>
      </template>
    </div>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-half has-text-centered">
            <h1 class="title poppins">Delover</h1>
            <h1 class="subtitle poppins" style="margin-bottom: 8px">Zbuduj swój profesjonalny serwer discord, baw się!</h1>
            <b-button size="is-large is-primary" tag="a" v-bind:href="`https://discord.com/api/oauth2/authorize?client_id=${botId}&permissions=8&scope=bot`">Dodaj bota</b-button>
          </div>
          <div class="column is-half is-hidden-mobile">
            <figure class="image is-512x512 is-centered-image">
              <img src="https://delover.pro/static/assets/delover.png" class="is-rounded">
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "Header",
  components: { Navbar },
  data() {
    return {
      botId: -1
    }
  }, mounted() {
    this.botId = process.env.VUE_APP_BOT_ID;
  }
}
</script>

<style scoped>
.is-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
