<template>
  <div id="app">
<!--    <router-view name="header"></router-view>-->
    <router-view v-on:changeMode="changeMode()"/>
  </div>
</template>
<script>
export default {
  data () {
    return {
      darkMode: true
    }
  }, methods: {
    changeMode() {
      console.log("duopa")
      this.darkMode = !this.darkMode;
    }
  }, watch: {
    darkMode: function () {
      // add/remove class to/from html tag
      let htmlElement = document.documentElement;

      if (this.darkMode) {
        localStorage.removeItem("theme");
        htmlElement.removeAttribute('theme');
      } else {
        localStorage.setItem("theme", 'light');
        htmlElement.setAttribute('theme', 'light');
      }
    }
  }, mounted() {
    this.$emit('changeMode')
    // let htmlElement = document.documentElement;
    // htmlElement.setAttribute('theme', 'light');
  }
}
</script>
<style>
</style>
