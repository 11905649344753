<template>
  <div>
    <Navbar></Navbar>
    <section class="section">
      <div class="container">
        <router-link v-bind:to="'/dashboard/' + $route.params.id">Powrót do wyboru kategorii</router-link>
        <h1 class="title poppins has-text-centered">Zarządzanie serwerem</h1>
        <br>
        <b-field label="Prefixy bota">
          <b-taginput
              :value="['?']"
              ellipsis
              maxtags="1"
              placeholder="Dodaj prefix">
          </b-taginput>
        </b-field>
        <b-field label="Rola administratora">
          <b-taginput
              :value="['Helper']"
              :data="['Admin', 'Moderator']"
              autocomplete
              :allow-new="false"
              field="user.first_name"
              maxtags="5"
              placeholder="Dodaj role">
          </b-taginput>
        </b-field>
        <b-field label="Rola wyciszony">
          <b-taginput
              :value="['Muted']"
              :data="['Muted']"
              autocomplete
              :allow-new="false"
              field="user.first_name"
              maxtags="1"
              placeholder="Dodaj role">
          </b-taginput>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "DashboardMainCategory",
  components: {
    Navbar
  }
}
</script>

<style scoped>

</style>
