import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Callback from "@/views/Callback";
import Dashboard from "@/views/Dashboard";
import DashboardSelectCategory from "@/views/DashboardSelectCategory";
import DashboardMainCategory from "@/views/DashboardMainCategory";
import DashboardWelcomeCategory from "@/views/DashboardWelcomeCategory";
import fourzerofour from "@/views/fourzerofour";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/dashboard/:id",
    name: "Dashboard",
    component: DashboardSelectCategory
  },
  {
    path: "/dashboard/:id/main",
    name: "Dashboard",
    component: DashboardMainCategory
  },
  {
    path: "/dashboard/:id/welcome",
    name: "Dashboard",
    component: DashboardWelcomeCategory
  },
  //404
  {path: "*", name: "Dashboard", component: fourzerofour}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
