<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered" v-if="authorized === undefined">
        <h1 class="title poppins">Autoryzacja...</h1>
        <h2 class="subtitle poppins">Autoryzujemy Twoje połączenie... Po prawidłowej autoryzacji zostaniesz automatycznie przeniesiony do panelu</h2>
      </div>
      <div class="container has-text-centered" v-if="authorized === false">
        <h1 class="title poppins">Autoryzacja nie powiodła się</h1>
        <h2 class="subtitle poppins" style="margin-bottom: 2px;">Zamknij to okno i spróbuj ponownie!</h2>
        <h3 class="subtitle poppins is-7" v-if="error">{{ error }}</h3>
      </div>
      <div class="container has-text-centered" v-if="authorized === true">
        <h1 class="title poppins">Autoryzacja powiodła się</h1>
        <h2 class="subtitle poppins">Przenoszenie do panelu...</h2>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "callback",
  mounted() {
    const bc = new BroadcastChannel('auth');

    axios.get(process.env.VUE_APP_API_URL + "/callback").then(response => {
      if(response.data.status) {
        this.success(response.data.token, bc)
      } else {
        this.failed(bc)
      }
    }).catch(error => this.failedError(error, bc));
  },
  data() {
    return {
      authorized: undefined,
      error: undefined
    }
  }, methods: {
    success(token, bc) {
      this.authorized = true
      document.cookie = "username=" + token;
      bc.postMessage("authorized");
      window.close();
    },
    failed(bc) {
      bc.postMessage("not authorized");
      this.authorized = false;
    },
    failedError(error, bc) {
      bc.postMessage("not authorized");
      this.authorized = false;
      this.error = error;
    }
  }
}
</script>

<style scoped>
</style>
