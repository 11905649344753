<template>
  <div class="container">
    <div class="columns is-mobile is-vcentered">
      <div class="column is-1">
        <p class="image is-64x64">
          <b-skeleton circle width="60px" height="60px"></b-skeleton>
        </p>
      </div>
      <div class="column is-4">
        <b-skeleton v-bind:width="width" height="25px"></b-skeleton>
      </div>
      <div class="column is-offset-5 ">
        <b-skeleton width="100px" height="40px"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServerSkeleton",
  props: {
    width: String
  }
}
</script>

<style scoped>

</style>
