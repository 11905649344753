<template>
  <p style="background-color: black">huj</p>
</template>

<script>
export default {
  name: "fourzerofour"
}
</script>

<style scoped>

</style>
