<template>
  <div>
    <Navbar></Navbar>
    <section class="section">
      <div class="container">
        <router-link v-bind:to="'/dashboard/' + $route.params.id">Powrót do wyboru kategorii</router-link>
        <h1 class="title poppins has-text-centered">Zarządzanie serwerem</h1>
        <br>
        <b-field label="Tytuł">
          <b-input v-model="title" maxlength="256"></b-input>
        </b-field>
        <b-field label="Opis">
          <b-input v-model="description" maxlength="4096" type="textarea"></b-input>
        </b-field>
        <b-field label="Footer">
          <b-input v-model="footer" maxlength="256"></b-input>
        </b-field>
        <label class="label" style="margin-bottom: 4px">Fields ({{fields.length}}/25)</label>
        <div v-for="(field, index) in fields" v-bind:key="field">
          <div class="container" style="padding-bottom: 10px; padding-top: 10px;">
            <div class="columns is-centered">
              <div class="column is-4">
                <b-field>
                  <b-input v-model="field.name"></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field>
                  <b-input v-model="field.description"></b-input>
                </b-field>
              </div>

              <div class="column is-4">
                <b-button type="is-primary" v-on:click="move(fields, index, index+1)" :disabled="index === fields.length-1">
                  <b-icon
                    pack="fas"
                    icon="chevron-down"
                    size="is-small">
                  </b-icon>
                </b-button>
                &nbsp;
                <b-button type="is-primary" v-on:click="move(fields, index, index-1)" :disabled="index == 0">
                  <b-icon
                      pack="fas"
                      icon="chevron-up"
                      size="is-small">
                  </b-icon>
                </b-button>
                &nbsp;
                &nbsp;
                <b-button type="is-danger" v-on:click="fields.splice(index, 1)">Usuń</b-button>
              </div>
            </div>
          </div>
        </div>
        <b-button type="is-primary" expanded v-on:click="fields.push({name: 'Tytył', description: 'Opis'})" :disabled="fields.length === 25">Dodaj field</b-button>
        <br>
        <label class="label" style="margin-bottom: 4px">Zdjęcie</label>
        <div class="block" style="margin-bottom: 5px">
          <b-radio v-model="imageType"
                   name="off"
                   native-value="off">
            Wyłącz
          </b-radio>
          <b-radio v-model="imageType"
                   name="avatar"
                   native-value="avatar"
          v-on:input="previewImageGenerate()">
            Avatar użytkownika
          </b-radio>
          <b-radio v-model="imageType"
                   name="custom"
                   native-value="custom"
          v-on:input="previewImage = undefined">
            Własne zdjęcie
          </b-radio>
        </div>
        <div v-if="imageType === 'custom'">
            <b-field class="file is-primary" :class="{'has-name': !!image}">
              <b-upload v-model="image" v-on:input="previewImageGenerate()" class="file-label" rounded>
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload" pack="fas" size="is-small"></b-icon>
                <span class="file-label">{{ image.name || "Kliknij, aby dodać zdjęcie"}}</span>
              </span>
              </b-upload>
            </b-field>
          <p class="help is-warning">Dozwolone formaty: .{{ endings.join(" .") }}</p>
        </div>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Tytuł autor">
              <b-input v-model="authorName"></b-input>
            </b-field>
          </div>
        </div>
        <h2 class="subtitle poppins" style="margin-bottom: 0">Podgląd Embeda</h2>
        <br>
        <DiscordEmbed v-bind:title="replaceVariables(title)" v-bind:author-name="replaceVariables(authorName)" v-bind:image="previewImage" v-bind:description="replaceVariables(description)" v-bind:footer="replaceVariables(footer)" v-bind:fields="fields"></DiscordEmbed>
<!--        https://codepen.io/2K4S/pen/yLOENYN-->
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import DiscordEmbed from "@/components/DiscordEmbed";

export default {
  name: "DashboardWelcomeCategory",
  components: {
    Navbar,
    DiscordEmbed
  }, data() {
    return {
      title: "Powitanie",
      description: "Witaj {user na serwerze!",
      footer: "Delover | Support",
      fields: [
        { name: "Siema", description: "dupa"}
      ],
      imageType: "off",
      image: {},
      previewImage: undefined,
      endings: ["png", "jpg", "jpeg"],
      authorName: "{user}"
    }
  }, methods: {
    replaceVariables(text) {
      return text.replace("{guild}", "Delover | Support").replace("{guild.members}", "420").replace("{guild.id}", "703156079890268180").replace("{user}", "@Aleks1123#0001").replace("{user.username}", "Aleks1123").replace("{user.tag}", "Aleks1123#0001").replace("{user.id}", "435029733344804874");
    },
    move(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }, previewImageGenerate() {
      if(this.imageType === "avatar") {
        this.previewImage = "https://cdn.discordapp.com/avatars/494017032283619329/d5b80d95530ee60fabddd4e69a9761de.png?size=1024";
        return;
      }
      const splitted = this.file.name.split(".");
      console.log(splitted[splitted.length-1])
      if (splitted.length === 0 || !this.endings.includes(splitted[splitted.length-1])) {
        this.image = undefined;
        return;
      }

      const reader = new FileReader()
      reader.readAsDataURL(this.image)
      reader.onload = () => {
        this.previewImage = reader.result
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }
}
</script>

<style scoped>

</style>
