<template>
  <div>
    <Navbar></Navbar>
    <section class="section">
      <div class="container has-text-centered">
        <center v-if="serverInfo === undefined">
          <p class="image is-128x128">
            <b-skeleton  circle width="128px" height="128px"></b-skeleton>
          </p>
        </center>
        <figure class="image is-128x128 is-centered-image" v-else>
          <img src="https://cdn.discordapp.com/icons/469204870331760640/3bde6c5d8702863199f960a0e5ee84c4.png?size=512" class="is-rounded">
        </figure>
        <br>
        <h1 class="title poppins" v-if="serverInfo === undefined"><b-skeleton width="40%" :animated="true" height="20px" position="is-centered"></b-skeleton></h1>
        <h1 class="title poppins" v-else>Zarządzanie serwerem {{  serverInfo.name }}</h1>
      </div>
      <br>
      <div class="container">
        <div class="columns">
          <div class="column is-4">
            <div class="box">
              <h1 class="title is-4 poppins">Ustawienia ogólne</h1>
              <h2 class="subtitle is-5 poppins">Zmiana prefixu bota, roli moderatora itd.</h2>
              <b-button type="is-primary" :expanded="true" v-on:click="$router.push(`/dashboard/${$route.params.id}/main`)">Zarządzaj</b-button>
            </div>
          </div>
          <div class="column is-4">
            <div class="box">
              <h1 class="title is-4 poppins">Powitania</h1>
              <h2 class="subtitle is-5 poppins">Witaj użytkowników na specjalnym kanale i nadawaj im rangi!</h2>
              <b-button type="is-primary" :expanded="true" v-on:click="$router.push(`/dashboard/${$route.params.id}/welcome`)">Zarządzaj</b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
export default {
  name: "DashboardSelectCategory",
  components: { Navbar },
  data() {
    return {
      serverInfo: undefined
    }
  },
  created() {
    axios.get(process.env.VUE_APP_API_URL + "/guilds").then(response => {
      if (response.data.status) {
        this.serverInfo = response.data.guilds.find(g => g.id === this.$route.params.id);
        if(this.serverInfo === undefined) {
          this.$buefy.dialog.alert({
            title: 'Nie znaleziono serwera',
            message: `Nie posiadasz uprawnień do tego serwera lub serwer nie istnieje!`,
            confirmText: 'Przejdź do wybierania serwera',
            type: 'is-success',
            onConfirm: () => this.$router.push({path: "/dashboard"})
          });
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
