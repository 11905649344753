<template>
  <b-modal
      v-model="showLoginModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Logowanie"
      aria-modal
      :can-cancel="false">
    <template>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Logowanie</p>
        </header>
        <section class="modal-card-body">
          <p>W nowym oknie otwarto logowanie. Jeśli z jakiegoś powodu nie pojawiła ci się ta karta, możęsz kliknąć guzik poniżej aby zalogować się w aktualnej karcie! Nie wyłączaj tej karty, ponieważ zostaniesz w niej później przeniesiony do panelu!</p>
        </section>
        <footer class="modal-card-foot">
          <b-button
              type="is-primary"
              label="Zaloguj się w tej karcie"
              @click="$router.go('callback')" />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "LoginModal",
  props: {
    showLoginModal: Boolean
  }
}
</script>

<style scoped>

</style>
