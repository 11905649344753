<template>
  <div>
    <navbar></navbar>
    <section class="section">
      <div class="container is-max-widescreen">
        <h2 class="subtitle is-3 poppins has-text-centered">Wybierz serwer, którym chcesz zarządzać</h2>
        <div v-if="guilds === undefined">
            <ServerSkeleton width="50%"></ServerSkeleton>
            <ServerSkeleton width="80%"></ServerSkeleton>
            <ServerSkeleton width="70%"></ServerSkeleton>
            <ServerSkeleton width="30%"></ServerSkeleton>
        </div>
        <Server v-for="guild in guilds" v-bind:key="guild" v-bind:icon="guild.icon" v-bind:name="guild.name" v-bind:id="guild.id" v-bind:is-bot="guild.bot"></Server>
      </div>
    </section>
  </div>
</template>

<script>
import Server from "@/components/Server";
import Navbar from "@/components/Navbar";
import axios from 'axios';
import ServerSkeleton from "@/components/ServerSkeleton";

export default {
  name: "Dashboard",
  components: {ServerSkeleton, Server, Navbar },
  data() {
    return {
      guilds: undefined
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL + "/guilds").then(response => {
      if (response.data.status) {
        this.guilds = response.data.guilds;
      }
    })
  }
}
</script>

<style scoped>

</style>
